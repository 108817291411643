import { DrawObject, DrawPart, DrawProduct, TextTemplate } from "@novorender/api";

import { Uom } from "./hooks/useUom";
import { convertAndFormatApiNumber } from "./utils";

function processTextTemplate(uom: Uom, tpl: TextTemplate) {
    return tpl
        .map((part) => {
            if (typeof part === "string") {
                return part;
            }

            return convertAndFormatApiNumber(uom, part.value, part.unitType, { decimals: part.decimals });
        })
        .join("");
}

export function processDrawPartTextTemplates(uom: Uom, part: DrawPart) {
    if (part.textTemplate?.length) {
        if (Array.isArray(part.textTemplate[0])) {
            const allTemplates = part.textTemplate as TextTemplate[][];
            (part as { text: string[][] }).text = allTemplates.map((subTemplates) =>
                subTemplates.map((tpl) => processTextTemplate(uom, tpl)),
            );
        } else {
            (part as { text: string }).text = processTextTemplate(uom, part.textTemplate as TextTemplate);
        }
    }
}

export function processDrawObjectTextTemplates(uom: Uom, obj: DrawObject) {
    for (const part of obj.parts) {
        processDrawPartTextTemplates(uom, part);
    }
}

export function processDrawProductTextTemplates(uom: Uom, drawProduct: DrawProduct) {
    for (const obj of drawProduct.objects) {
        processDrawObjectTextTemplates(uom, obj);
    }
}
