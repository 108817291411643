import { useCallback, useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { highlightActions, useDispatchHighlighted } from "contexts/highlighted";
import { useGetAlignments } from "features/alignment/hooks/useGetAlignments";
import { alignmentActions } from "features/alignment/slice";
import { renderActions, selectViewMode } from "features/render";
import { AsyncStatus, ViewMode } from "types/misc";

import { selectSelectedCenterLineId, selectSelectedProfile } from "../selectors";

// When changing deviation - set corresponding center line alignment
export function useSetCenterLineAlignment() {
    const {
        state: { view },
    } = useExplorerGlobals();
    const dispatch = useAppDispatch();
    const selectedProfile = useAppSelector(selectSelectedProfile);
    const selectedCenterLineId = useAppSelector(selectSelectedCenterLineId);
    const centerLine =
        selectedProfile && selectedCenterLineId
            ? selectedProfile.subprofiles.find((sp) => sp.centerLine?.brepId === selectedCenterLineId)?.centerLine
            : undefined;
    const followPathId = centerLine?.objectId;
    const dispatchHighlighted = useDispatchHighlighted();
    const installedFollowPathId = useRef<number>();
    const active = useAppSelector(selectViewMode) === ViewMode.Deviations;
    const alignments = useGetAlignments();

    const restore = useCallback(() => {
        if (installedFollowPathId.current !== undefined) {
            dispatch(alignmentActions.setAlignment(undefined));
            dispatch(alignmentActions.setSelectedStation(undefined));
            dispatchHighlighted(highlightActions.remove([installedFollowPathId.current]));

            installedFollowPathId.current = undefined;
        }
    }, [dispatch, dispatchHighlighted]);

    useEffect(() => {
        return restore;
    }, [restore]);

    useEffect(() => {
        if (!followPathId || !view || !active || alignments.status !== AsyncStatus.Success) {
            restore();
            return;
        }

        installedFollowPathId.current = undefined;
        let alignmentName: string = "";
        for (const a of Object.entries(alignments.data)) {
            if (a[1].objectId == followPathId) {
                alignmentName = a[0];
                break;
            }
        }

        dispatch(renderActions.setViewMode(ViewMode.Deviations));
        dispatch(alignmentActions.setAlignment({ name: alignmentName, id: followPathId }));
        dispatch(renderActions.setMainObject(followPathId));
        dispatchHighlighted(highlightActions.setIds([followPathId]));

        installedFollowPathId.current = followPathId;
    }, [view, followPathId, dispatch, dispatchHighlighted, restore, active, alignments]);
}
