export type HorizontalDisplaySettings = {
    stations: StationSettings;
    verticalSegment: VerticalSegmentSettings;
    horizontalSegment: HorizontalSegmentSettings;
    showPointsOfCurvature: boolean;
    showCurvatures: boolean;
    showAlignmentNames: boolean;
    coloringType: ColoringType;
};

export type VerticalSegmentSettings = {
    show: boolean;
    curvature: boolean;
    elevation: boolean;
    length: boolean;
    slope: boolean;
};

export type HorizontalSegmentSettings = {
    show: boolean;
    curvature: boolean;
    elevation: boolean;
    length: boolean;
};

export type StationSettings = {
    show: boolean;
    interval: number;
    slope: boolean;
    elevation: boolean;
};

export enum ColoringType {
    Standard,
    Curvature,
}

export type AlignmentId = {
    id: number;
    /** Unique name of the alignment, which is made out of `{projectName}/{alignmentName}` */
    name: string;
    alignmentName: string;
    projectName: string;
    brepId?: string;
};

export type TracerType = "off" | "vertical" | "normal";

export enum AlignmentView {
    Pinhole = "pinhole",
    OrthoCrossSection = "orthoCrossSection",
    OrthoTopDown = "orthoTopDown",
}
