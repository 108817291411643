import { OpenInNew } from "@mui/icons-material";
import { Box, IconButton, Snackbar } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MemoryRouter, Route } from "react-router-dom";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { LinearProgress } from "components";
import { ObjectTree } from "components/objectTree/objectTree";
import { BreadcrumbNodeSpec, ListNode, Node, Tree } from "components/objectTree/types";
import { defaultBreadcrumbNodeLabel, expandGroup, makeNodeList } from "components/objectTree/utils";
import SimpleSnackbar from "components/simpleSnackbar";
import { featuresConfig } from "config/features";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { groupsActions } from "features/groups";
import { selectMainObject } from "features/render";
import { useAbortController } from "hooks/useAbortController";
import { useOpenWidget } from "hooks/useOpenWidget";
import { AsyncStatus } from "types/misc";
import { getFilePathFromObjectPath } from "utils/objectData";
import { getObjectData } from "utils/search";

import { useFileTypeObjects } from "../hooks/useFileTypeObjects";
import { SaveToGroups } from "../routes/saveToGroups";
import { ModelTreeFilter, ModelTreeNodeData } from "../types";
import { collapseNode, expandNode, getObjectParentFile, loadMore, selectNode } from "../utils";
import { ModelTreeNode } from "./modelTreeNode";

export function ModelTree({
    header,
    fileLevel = false,
    fileTypes = [],
    tree,
    setTree,
    autoHeight,
    isInsideWidget,
}: {
    header?: React.ReactNode;
    fileLevel?: boolean;
    fileTypes?: ModelTreeFilter[];
    tree: Tree<ModelTreeNodeData>;
    setTree: Dispatch<SetStateAction<Tree<ModelTreeNodeData>>>;
    autoHeight?: boolean;
    isInsideWidget?: boolean;
}) {
    const {
        state: { db, view },
    } = useExplorerGlobals(true);
    const { t } = useTranslation();
    const mainObject = useAppSelector(selectMainObject);
    const mainObjectRef = useRef(mainObject);
    mainObjectRef.current = mainObject;
    const [loading, setLoading] = useState(false);
    const [createdGroupId, setCreatedGroupId] = useState<string | undefined>();
    const [height, setHeight] = useState(0);
    const [filterAbortController, abortFilter] = useAbortController();
    const openWidget = useOpenWidget();
    const dispatch = useAppDispatch();
    const { fileTypeObjects, loadFileTypeObjects } = useFileTypeObjects();

    const [highlightedId, setHighlightedId] = useState(mainObject);
    const [error, setError] = useState("");

    const treeRef = useRef(tree);
    treeRef.current = tree;

    const highlightPath = useMemo(() => {
        const node =
            typeof highlightedId === "number"
                ? Object.values(tree).find((v) => v.data.object.id === highlightedId)
                : null;
        return node?.data.object.path;
    }, [highlightedId, tree]);

    const activeFileTypes = fileTypeObjects.status === AsyncStatus.Success ? fileTypeObjects.data : undefined;

    const handleTreeLoaderError = useCallback(
        (
            ex: unknown,
            rollback: undefined | ((tree: Tree<ModelTreeNodeData>) => Tree<ModelTreeNodeData>),
            resetLoading = false,
        ) => {
            if ((ex as { name: string }).name === "AbortError") {
                return;
            }
            console.warn(ex);
            setError("errorOccurred");
            if (rollback) {
                setTree(rollback);
            }
            if (resetLoading) {
                setLoading(false);
            }
        },
        [setTree],
    );

    useEffect(() => {
        abortFilter();
    }, [abortFilter, fileTypes]);

    useEffect(() => {
        loadFileTypeObjects({
            fileTypes,
            setTree,
            abortSignal: filterAbortController.current.signal,
        });
    }, [loadFileTypeObjects, fileTypes, filterAbortController, setTree]);

    const tryExpandNode = useCallback(
        async (tree: Tree<ModelTreeNodeData>, path: string) => {
            const expander = expandNode({
                tree,
                path,
                db,
                abortSignal: filterAbortController.current.signal,
                fileTypes: activeFileTypes,
            });
            setTree(expander.tree);
            if (!expander.load) {
                return;
            }

            try {
                const patch = await expander.load();
                setTree((tree) => patch(tree, true));
            } catch (ex) {
                handleTreeLoaderError(ex, expander.rollback);
            }
        },
        [db, filterAbortController, activeFileTypes, handleTreeLoaderError, setTree],
    );

    useEffect(() => {
        expandOrSelectNode();

        async function expandOrSelectNode() {
            if (fileTypeObjects.status !== AsyncStatus.Success) {
                return;
            }
            const abortSignal = filterAbortController.current.signal;

            let obj =
                typeof mainObject === "number" &&
                (Object.values(treeRef.current).find((node) => node.data.object.id === mainObject)?.data.object ??
                    (await getObjectData({ db, view, id: mainObject })));

            if (abortSignal.aborted) {
                return;
            }

            if (obj) {
                if (fileLevel) {
                    const parent = await getObjectParentFile({
                        db,
                        mainObject: obj,
                        fileTypes: fileTypeObjects.data.length ? fileTypeObjects.data : undefined,
                        tree: treeRef.current,
                        abortSignal: abortSignal,
                    });
                    if (parent) {
                        obj = parent;
                    }
                }
                setHighlightedId(obj.id);
                if (abortSignal.aborted) {
                    return;
                }
                const loader = selectNode({
                    tree: treeRef.current,
                    db,
                    abortSignal: abortSignal,
                    mainObject: obj,
                    fileTypes: fileTypeObjects.data.length ? fileTypeObjects.data : undefined,
                });

                if (loader.tree === treeRef.current && !treeRef.current[""].children) {
                    tryExpandNode(treeRef.current, "");
                } else {
                    setTree(loader.tree);
                    if (loader.load) {
                        setLoading(true);
                        try {
                            const patch = await loader.load();
                            const relevant = mainObject === mainObjectRef.current;
                            setTree((tree) => patch(tree, relevant));
                            setLoading(false);
                        } catch (ex) {
                            handleTreeLoaderError(ex, loader.rollback, true);
                        }
                    }
                }
            } else if (!treeRef.current[""].children) {
                tryExpandNode(treeRef.current, "");
            }
        }
    }, [
        db,
        view,
        mainObject,
        fileTypeObjects,
        fileLevel,
        filterAbortController,
        tryExpandNode,
        handleTreeLoaderError,
        setTree,
    ]);

    const handleLoadMore = useCallback(
        async ({ node }: ListNode<ModelTreeNodeData>) => {
            const loader = loadMore({
                db,
                tree,
                path: node.path,
                fileTypes: activeFileTypes,
                abortSignal: filterAbortController.current.signal,
            });
            setTree(loader.tree);
            if (loader.load) {
                try {
                    const patch = await loader.load();
                    setTree((tree) => patch(tree, true));
                } catch (ex) {
                    handleTreeLoaderError(ex, loader.rollback);
                }
            }
        },
        [db, tree, activeFileTypes, filterAbortController, handleTreeLoaderError, setTree],
    );

    const handleToggleExpand = useCallback(
        async ({ node, nodes }: ListNode<ModelTreeNodeData>, specificNode?: Node<ModelTreeNodeData>) => {
            if (specificNode) {
                if (specificNode.expanded) {
                    setTree(collapseNode({ tree, path: specificNode.path }));
                } else {
                    tryExpandNode(tree, specificNode.path);
                }
                return;
            }

            const firstNode = node;
            const lastNode = nodes?.at(-1) ?? node;
            const allExpanded = (nodes?.every((n) => n.expanded) ?? true) && node.expanded;
            if (allExpanded) {
                setTree(collapseNode({ tree, path: firstNode.path }));
            } else {
                tryExpandNode(tree, lastNode.path);
            }
        },
        [tree, tryExpandNode, setTree],
    );

    const handleExpandGroup = useCallback(
        ({ node }: ListNode<ModelTreeNodeData>) => {
            setTree((tree) => expandGroup(tree, node.path, 100));
        },
        [setTree],
    );

    const handleGroupCreated = useCallback((groupId: string) => {
        setCreatedGroupId(groupId);
    }, []);

    const handleOpenGroup = useCallback(() => {
        openWidget(featuresConfig.groups.key, { force: true });
        if (createdGroupId) {
            dispatch(groupsActions.setHighlightGroupInWidget(createdGroupId));
            setCreatedGroupId(undefined);
        }
    }, [dispatch, createdGroupId, openWidget]);

    const list = useMemo(() => makeNodeList({ tree, splitNodeCollapseAt }), [tree]);

    const itemSize = 28;

    useEffect(() => {
        if (autoHeight) {
            setHeight((height) => Math.max(height, list.length * itemSize));
        }
    }, [list, autoHeight]);

    return (
        <MemoryRouter>
            <Route path="/saveToGroups">
                <SaveToGroups onCreate={handleGroupCreated} headerShadow={isInsideWidget} />
            </Route>
            <Route>
                {({ match }) => (
                    <Box
                        sx={{
                            position: "relative",
                            minHeight: `${itemSize}px`,
                            height: autoHeight ? `${height}px` : "100%",
                            display: match?.isExact ? "flex" : "none",
                            flexDirection: "column",
                        }}
                    >
                        {header}
                        {(tree[""].loading || loading) && <LinearProgress />}
                        <Box sx={{ position: "relative", flex: "auto" }}>
                            <ReactVirtualizedAutoSizer>
                                {({ height, width }) => (
                                    <ObjectTree
                                        width={width}
                                        height={height}
                                        itemSize={itemSize}
                                        tree={tree}
                                        list={list}
                                        scrollTo={highlightPath}
                                        ListItem={(props) => (
                                            <ModelTreeNode
                                                {...props}
                                                setLoading={setLoading}
                                                onToggleExpanded={handleToggleExpand}
                                                onExpandGroup={handleExpandGroup}
                                                abortSignal={filterAbortController.current.signal}
                                                onLoadMore={handleLoadMore}
                                                highlightPath={highlightPath}
                                            />
                                        )}
                                        buildBreadcrumbs={buildBreadcrumbs}
                                    />
                                )}
                            </ReactVirtualizedAutoSizer>
                        </Box>
                    </Box>
                )}
            </Route>
            <SimpleSnackbar hideDuration={3000} close={() => setError("")} message={error ? t(error) : ""} />
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                sx={{
                    width: { xs: "auto", sm: 350 },
                    bottom: { xs: "auto", sm: 24 },
                    top: { xs: 24, sm: "auto" },
                }}
                autoHideDuration={3500}
                open={Boolean(createdGroupId)}
                onClose={() => setCreatedGroupId(undefined)}
                message={t("groupCreatedSaveInGroups")}
                action={
                    <IconButton size="small" color="inherit" onClick={handleOpenGroup}>
                        <OpenInNew fontSize="small" />
                    </IconButton>
                }
            />
        </MemoryRouter>
    );
}

// If there's file in the path - collapse things before and after.
// Otherwise collapse everything but the last 3 nodes.
function buildBreadcrumbs(nodes: Node<ModelTreeNodeData>[]) {
    const fileIndex = nodes.findIndex((node) => getFilePathFromObjectPath(node.path));
    if (fileIndex === -1) {
        return nodes.length > 3
            ? [
                  nodes.slice(0, -2),
                  ...nodes.slice(-2).map((node) => ({ node, label: defaultBreadcrumbNodeLabel(node) })),
              ]
            : nodes.map((node) => ({ node, label: defaultBreadcrumbNodeLabel(node) }));
    }

    const result: BreadcrumbNodeSpec<ModelTreeNodeData>[] = [];
    if (fileIndex > 1) {
        result.push(nodes.slice(0, fileIndex));
    } else {
        for (let i = 0; i < fileIndex; i++) {
            result.push({ node: nodes[i], label: defaultBreadcrumbNodeLabel(nodes[i]) });
        }
    }

    result.push({ node: nodes[fileIndex], label: defaultBreadcrumbNodeLabel(nodes[fileIndex]) });

    if (nodes.length - fileIndex > 2) {
        result.push(nodes.slice(fileIndex + 1, nodes.length - 1));
        const lastNode = nodes.at(-1)!;
        result.push({ node: lastNode, label: defaultBreadcrumbNodeLabel(lastNode) });
    } else {
        for (let i = fileIndex + 1; i < nodes.length; i++) {
            result.push({ node: nodes[i], label: defaultBreadcrumbNodeLabel(nodes[i]) });
        }
    }

    return result;
}

/**
 * Nodes are collapsed into a single node if they have only one child to reduce nesting.
 * Collapsed node label may end up being too long.
 * This function can be used to decide when to split the node.
 * @returns true if the tree node should be split after the given node
 */
function splitNodeCollapseAt(node: Node<ModelTreeNodeData>): boolean {
    return getFilePathFromObjectPath(node.name) !== null;
}
