import { Close } from "@mui/icons-material";
import { Button, IconButton, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Permission } from "apis/dataV2/permissions";
import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { featuresConfig } from "config/features";
import { useCheckProjectPermission } from "hooks/useCheckProjectPermissions";
import { useOpenWidget } from "hooks/useOpenWidget";
import { explorerActions, selectCameraIsTooFar } from "slices/explorer";

export function CameraIsTooFarMessage() {
    const { t } = useTranslation();
    const show = useAppSelector(selectCameraIsTooFar);
    const dispatch = useAppDispatch();
    const openWidget = useOpenWidget();
    const checkPermission = useCheckProjectPermission();

    if (!show) {
        return null;
    }

    const close = () => {
        dispatch(explorerActions.setCameraIsTooFar(null));
    };

    const configure = () => {
        close();
        openWidget(featuresConfig.advancedSettings.key, { force: true });
        dispatch(
            explorerActions.setHighlightSetting({
                route: "/camera",
                accordion: "clipping3d",
                field: "#clipping3d-far",
            }),
        );
    };

    const canConfigure = checkPermission(Permission.SceneManage);

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            sx={{
                width: { xs: "auto", sm: 350 },
                bottom: { xs: "auto", sm: 88 },
                top: { xs: 24, sm: "auto" },
                pointerEvents: "auto",
            }}
            open={true}
            onClose={close}
            autoHideDuration={show === "flyTo" ? 15000 : null}
            message={t(`${show === "initial" ? "project" : "selection"}BoundsAreTooWide${canConfigure ? "Admin" : ""}`)}
            action={
                <>
                    {canConfigure && (
                        <Button color="primary" size="small" onClick={configure}>
                            {t("adjustCamera")}
                        </Button>
                    )}
                    <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={close}>
                        <Close />
                    </IconButton>
                </>
            }
        />
    );
}
