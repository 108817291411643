import { UnitType as ApiUnitType } from "@novorender/api";

import { type useUom } from "./hooks/useUom";
import { FormatOptions, UNIT_TYPE_BY_ABBREVIATION, UnitType } from "./spec";

export function convertAndFormatApiNumber(
    uom: ReturnType<typeof useUom>,
    value: number,
    unitType: ApiUnitType,
    formatOpts?: FormatOptions,
) {
    return uom.format(uom.convertToUi(value, unitType as UnitType), unitType as UnitType, formatOpts);
}

export function convertAndFormat(
    uom: ReturnType<typeof useUom>,
    value: number,
    unitType: UnitType,
    formatOpts?: FormatOptions,
) {
    return uom.format(uom.convertToUi(value, unitType), unitType, formatOpts);
}

export function convertAndFormatForInput(uom: ReturnType<typeof useUom>, value: number, unitType: UnitType) {
    return uom.formatForInput(uom.convertToUi(value, unitType), unitType);
}

export function extractPropertyValueAndUnitType(value: string) {
    const parts = value.split("@", 2);
    if (parts.length < 2) {
        return;
    }
    const number = Number(parts[0]);
    const unitType = UNIT_TYPE_BY_ABBREVIATION.get(parts[1]);
    if (Number.isNaN(number) || !unitType) {
        return;
    }

    return { value: number, unitType };
}
