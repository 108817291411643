import { Alignment, DuoMeasurementValues } from "@novorender/api";
import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { highlightActions, useDispatchHighlighted } from "contexts/highlighted";
import { selectAlignmentView } from "features/alignment";
import { useGetAlignments } from "features/alignment/hooks/useGetAlignments";
import { useGoToStation } from "features/alignment/hooks/useGoToStation";
import { alignmentActions } from "features/alignment/slice";
import { AlignmentView } from "features/alignment/types";
import { renderActions } from "features/render";
import { AsyncStatus, ViewMode } from "types/misc";

import { deviationsActions } from "../deviationsSlice";
import { selectDeviationProfileList, selectSelectedProfileId, selectSelectedSubprofileIndex } from "../selectors";

export function useSelectDeviationProfile() {
    const {
        state: { view },
    } = useExplorerGlobals();
    const profiles = useAppSelector(selectDeviationProfileList);
    const currentProfileId = useAppSelector(selectSelectedProfileId);
    const currentSubprofileIndex = useAppSelector(selectSelectedSubprofileIndex);
    const alignments = useGetAlignments();
    const goToProfile = useGoToStation();
    const alignmentView = useAppSelector(selectAlignmentView);

    const dispatch = useAppDispatch();
    const dispatchHighlighted = useDispatchHighlighted();

    return useCallback(
        async ({ profileId, subprofileIndex }: { profileId?: string; subprofileIndex?: number }) => {
            if (!profileId) {
                profileId = currentProfileId;
                subprofileIndex = currentSubprofileIndex ?? 0;
            } else if (subprofileIndex === undefined) {
                subprofileIndex = 0;
            }
            if (!profileId || subprofileIndex === undefined) {
                return;
            }
            const profile = profiles.find((p) => p.id === profileId);
            const sp = profile?.subprofiles[subprofileIndex];
            if (!sp) {
                return;
            }

            dispatch(renderActions.setViewMode(ViewMode.Deviations));

            dispatch(deviationsActions.setSelectedProfileId(profileId));
            dispatch(deviationsActions.setSelectedSubprofileIndex(subprofileIndex));

            if (sp.centerLine?.objectId === undefined || !view || alignments.status !== AsyncStatus.Success) {
                return;
            }

            const followPathId = sp.centerLine.objectId;

            let currentAlignment: Alignment | undefined;
            let alignmentName: string = "";
            for (const a of Object.entries(alignments.data)) {
                if (a[1].objectId === followPathId) {
                    alignmentName = a[0];
                    currentAlignment = a[1];
                    break;
                }
            }

            dispatch(alignmentActions.setAlignment({ name: alignmentName, id: followPathId }));
            dispatch(renderActions.setMainObject(followPathId));
            dispatch(alignmentActions.setSelectedStation(undefined));
            dispatch(renderActions.setClippingPlanes({ planes: [] }));
            dispatchHighlighted(highlightActions.setIds([followPathId]));

            if (view.measure) {
                const segment = await view.measure.core.pickCurveSegment(followPathId);
                if (segment) {
                    const measure = await view.measure.core.measure(segment, {
                        drawKind: "vertex",
                        ObjectId: -1,
                        parameter: view.renderState.camera.position,
                    });
                    if (measure && currentAlignment) {
                        const duoMeasure = measure as DuoMeasurementValues;
                        if (duoMeasure.measureInfoB && typeof duoMeasure.measureInfoB.parameter === "number") {
                            const pos = Math.max(
                                sp.centerLine.parameterBounds[0],
                                Math.min(sp.centerLine.parameterBounds[1], duoMeasure.measureInfoB.parameter),
                            );

                            if (alignmentView !== AlignmentView.Pinhole) {
                                const fpObj = await view.measure?.followPath.followParametricObjects([followPathId], {
                                    cylinderMeasure: "center",
                                });

                                if (fpObj) {
                                    goToProfile({
                                        fpObj,
                                        station: pos,
                                        newAlignmentView: alignmentView,
                                        keepOffset: false,
                                        updateStationInfo: true,
                                    });
                                }
                            }
                        }
                    } else if (currentAlignment) {
                        const stationInfo = view.measure.road.getStationInfoAtPoint(
                            currentAlignment,
                            currentAlignment.points[0],
                        );
                        dispatch(alignmentActions.setSelectedStation(stationInfo));
                    }
                }
            }
        },
        [
            dispatch,
            profiles,
            view,
            dispatchHighlighted,
            alignments,
            goToProfile,
            alignmentView,
            currentProfileId,
            currentSubprofileIndex,
        ],
    );
}
