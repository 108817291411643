import { HierarcicalObjectReference } from "@novorender/data-js-api";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { useSceneId } from "hooks/useSceneId";
import { selectIsOnline } from "slices/explorer";
import { AsyncStatus } from "types/misc";
import { getManualCache } from "utils/manualCache";
import { jsonResponseForCache } from "utils/misc";
import { getObjectNameFromPath, getParentPath } from "utils/objectData";
import { iterateAsync, searchByPatterns } from "utils/search";

import { selectAlignmentIds } from "../selectors";
import { alignmentActions } from "../slice";
import { AlignmentId } from "../types";

export function useLoadAlignments({ skip }: { skip?: boolean } = {}) {
    const {
        state: { db },
    } = useExplorerGlobals();
    const { t } = useTranslation();
    const alignments = useAppSelector(selectAlignmentIds);
    const dispatch = useAppDispatch();
    const projectId = useSceneId();
    const isOnline = useAppSelector(selectIsOnline);
    const isOnlineRef = useRef(isOnline);
    useEffect(() => {
        isOnlineRef.current = isOnline;
    });

    useEffect(() => {
        if (!skip && alignments.status === AsyncStatus.Initial) {
            getAlignments();
        }

        async function getAlignments() {
            if (!db) {
                return;
            }

            dispatch(alignmentActions.setAlignmentIds({ status: AsyncStatus.Loading }));

            const cacheKey = `/derived/projects/${projectId}/alignments`;

            try {
                let alignmentIds = [] as AlignmentId[];

                const cache = await getManualCache();

                const loadFromCache = async () => {
                    const resp = await cache.match(cacheKey);
                    if (resp) {
                        alignmentIds = await resp.json();
                    } else {
                        throw new Error("No cached value for alignments");
                    }
                };

                if (!isOnlineRef.current) {
                    await loadFromCache();
                } else {
                    try {
                        const refsWithPathId: HierarcicalObjectReference[] = [];
                        await searchByPatterns({
                            db,
                            searchPatterns: [{ property: "Novorender/PathId" }],
                            full: true,
                            callback: (refs) => {
                                refsWithPathId.push(...refs);
                            },
                        });

                        const ifcProjectPromiseMap = new Map<string, Promise<string | undefined>>();

                        alignmentIds = await Promise.all(
                            refsWithPathId.map(async (ref) => {
                                const meta = await ref.loadMetaData();

                                // Get IFC project name
                                let ifcProjectName: undefined | string;
                                if (meta.properties.some((p) => p[0] === "IfcClass")) {
                                    const parentPath = getParentPath(meta.path);
                                    let projectPromise = ifcProjectPromiseMap.get(parentPath);
                                    if (!projectPromise) {
                                        projectPromise = iterateAsync({
                                            iterator: db.search(
                                                {
                                                    parentPath: meta.path,
                                                    descentDepth: -100,
                                                    searchPattern: [{ property: "IfcClass", value: "IfcProject" }],
                                                },
                                                undefined,
                                            ),
                                            count: 1,
                                        }).then(
                                            ([[ifcProject]]) => ifcProject && getObjectNameFromPath(ifcProject.path),
                                        );
                                        ifcProjectPromiseMap.set(parentPath, projectPromise);
                                    }
                                    ifcProjectName = await projectPromise;
                                }

                                const alignmentId = getAlignmentIdFromObject(ref, ifcProjectName);
                                alignmentId.brepId = meta.properties.find((p) => p[0] === "Novorender/PathId")![1];
                                return alignmentId;
                            }),
                        );

                        if (alignmentIds.length == 0) {
                            //Legacy
                            await searchByPatterns({
                                db,
                                searchPatterns: [
                                    {
                                        property: "Novorender/Path",
                                        value: "true",
                                        exact: true,
                                    },
                                ],
                                callback: (refs) => {
                                    for (const ref of refs) {
                                        alignmentIds.push(getAlignmentIdFromObject(ref, undefined));
                                    }
                                },
                            });
                        }

                        cache.put(cacheKey, jsonResponseForCache(alignmentIds));
                    } catch (e) {
                        console.warn(e);
                        await loadFromCache();
                    }
                }

                alignmentIds.sort((a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "accent" }));
                dispatch(alignmentActions.setAlignmentIds({ status: AsyncStatus.Success, data: alignmentIds }));
            } catch (e) {
                console.warn(e);
                dispatch(
                    alignmentActions.setAlignmentIds({
                        status: AsyncStatus.Error,
                        msg: t("loadPathToFollowListError"),
                    }),
                );
            }
        }
    }, [db, alignments, dispatch, skip, projectId, t]);
}

/** For landxml path structure is as following:
 * `...{file_name}.xml/{file_name}_{alignment_name}/Center line`
 * We can identify this pattern and extract file_name (which is project name) and alignment_name
 *
 * Otherwise we assume it's IFC with format `...{project_name}/(...)/{alignment_name}`
 * IFC project name can't be inferred from the path alone, so it's fetched outside and passed as `projectName`
 */
function getAlignmentIdFromObject(
    { id, path }: HierarcicalObjectReference,
    projectName: string | undefined,
): AlignmentId {
    const parentPath = getParentPath(path);
    const parentName = getObjectNameFromPath(parentPath);
    const grandparentName = getObjectNameFromPath(getParentPath(parentPath));
    const fileName = grandparentName.match(/^(.+)\.xml$/i)?.[1];
    if (fileName && parentName.startsWith(fileName + "_") && parentName.length > fileName.length + 1) {
        const alignmentName = parentName.slice(fileName.length + 1);
        return { id, name: `${fileName}/${alignmentName}`, alignmentName, projectName: fileName };
    }

    const alignmentName = getObjectNameFromPath(path);
    projectName ??= parentName;
    return { id, name: `${projectName}/${alignmentName}`, alignmentName, projectName };
}
