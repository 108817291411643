import convert from "convert";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app/redux-store-interactions";
import { selectUnitSystem } from "slices/explorer";

import {
    FormatOptions,
    Unit,
    UNIT_SYSTEMS,
    UNIT_TYPES,
    UNITS,
    UnitSystem,
    UnitType,
    UOM_DEFAULT_DECIMALS,
} from "../spec";

export function useUom() {
    const { t } = useTranslation();
    const unitSystem = useAppSelector(selectUnitSystem);

    return useMemo(() => new Uom(unitSystem, t), [unitSystem, t]);
}

export class Uom {
    constructor(
        private readonly unitSystem: UnitSystem,
        private readonly t: ReturnType<typeof useTranslation>["t"],
    ) {
        this.unitSystem = unitSystem;
    }

    private getUnitTypeInfo(unitType: UnitType) {
        const unitTypeInfo = UNIT_TYPES[unitType];
        if (!unitTypeInfo) {
            throw new Error(`Unknown unit type '${unitType}'`);
        }
        return unitTypeInfo;
    }

    convertToUi(value: number, unitType: UnitType) {
        const unitTypeInfo = this.getUnitTypeInfo(unitType);
        const uiUnit = UNIT_SYSTEMS[this.unitSystem].unitTypes[unitType];
        return convert(value, unitTypeInfo.baseUnit).to(uiUnit);
    }

    convertToBase(value: number, unitType: UnitType) {
        const unitTypeInfo = this.getUnitTypeInfo(unitType);
        const uiUnit = UNIT_SYSTEMS[this.unitSystem].unitTypes[unitType];
        return convert(value, uiUnit).to(unitTypeInfo.baseUnit);
    }

    format(value: number, unitType: UnitType, opts?: FormatOptions) {
        const uiUnit = UNIT_SYSTEMS[this.unitSystem].unitTypes[unitType];
        const unitInfo = UNITS[uiUnit];
        const decimals = opts?.decimals ?? unitInfo.decimals ?? UOM_DEFAULT_DECIMALS;

        switch (uiUnit) {
            case Unit.deg:
                return `${value.toFixed(decimals)}°`;
            case Unit.ft:
                return formatFeetInches(value, decimals !== 0);
            default: {
                const unitLabel = this.t(`unitTypes.${uiUnit}`);
                return `${value.toFixed(decimals)} ${unitLabel}`;
            }
        }
    }

    formatForInput(value: number, unitType: UnitType) {
        const uiUnit = UNIT_SYSTEMS[this.unitSystem].unitTypes[unitType];
        const unitInfo = UNITS[uiUnit];
        const decimals = unitInfo.decimals ?? UOM_DEFAULT_DECIMALS;

        return value.toFixed(decimals);
    }

    getUnitLabel(unitType: UnitType) {
        const uiUnit = UNIT_SYSTEMS[this.unitSystem].unitTypes[unitType];
        return this.t(`unitTypes.${uiUnit}`);
    }
}

// Based on https://github.com/procore/bim-webviewer/blob/develop/src/unit_util.ts
function formatFeetInches(value: number, includeInches = true) {
    const FOOT_TICK_MARK = "’";
    const INCH_TICK_MARK = "”";

    let feet = parseInt(`${Math.abs(Math.floor(value))}`, 10);
    const fracfeet = Math.abs(value) - feet;
    const fracinch = fracfeet * 12;
    let inches = parseInt(`${Math.abs(Math.floor(fracinch))}`, 10);
    const frac = fracinch - inches;
    let fracStr = vulgarFraction(frac);

    if (fracStr === "1") {
        inches += 1;
        fracStr = "";
    }
    if (inches === 12) {
        feet += 1;
        inches = 0;
    }

    let feetStr = "";
    let feetInchSep = "";
    let inchInd = "";
    let inchStr = "";

    if (feet !== 0) {
        feetStr = `${feet}${FOOT_TICK_MARK}`;
    }
    if (!includeInches) {
        return feetStr || `0${FOOT_TICK_MARK}`;
    }

    inchStr = `${inches}`;

    if ((inchStr.length > 0 || fracStr.length > 0) && feetStr.length > 0) {
        feetInchSep = " ";
    }

    if (inchStr.length > 0 || fracStr.length > 0) {
        inchInd = INCH_TICK_MARK;
    }

    if (fracStr.length > 0 && inches === 0) {
        inchStr = "";
    }

    const sign = value < 0 ? "-" : "";

    return `${sign}${feetStr}${feetInchSep}${inchStr}${fracStr}${inchInd}`;
}

function vulgarFraction(frac: number) {
    // if match exact
    if (frac === 1 / 3) {
        return "⅓";
    }
    if (frac === 2 / 3) {
        return "⅔";
    }
    if (frac <= 1 / 16) {
        return ""; // invisible zero
    }
    if (frac > 1 / 16 && frac <= 1 / 12) {
        return "⅛";
    }
    if (frac > 1 / 12 && frac <= 3 / 16) {
        return "⅛"; // 2/16
    }
    if (frac > 3 / 16 && frac <= 5 / 16) {
        return "¼"; // 4/16
    }
    if (frac > 5 / 16 && frac <= 7 / 16) {
        return "⅜"; // 6/16
    }
    if (frac > 7 / 16 && frac <= 9 / 16) {
        return "½"; // 8/16
    }
    if (frac > 9 / 16 && frac <= 11 / 16) {
        return "⅝"; // 10/16
    }
    if (frac > 11 / 16 && frac <= 13 / 16) {
        return "¾"; // 12/16
    }
    if (frac > 13 / 16 && frac <= 15 / 16) {
        return "⅞"; // 14/16
    }
    if (frac > 15 / 16) {
        // Hmmm idk how I feel about this. Make sure we have a test case for it.
        return "1"; // add to whole number
    }
    return "";
}
