import { FollowParametricObject, rotationFromDirection } from "@novorender/api";
import { glMatrix, quat, vec3 } from "gl-matrix";
import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { getCameraDir } from "features/engine2D/utils";
import { CameraType, renderActions } from "features/render";
import { AsyncStatus } from "types/misc";

import { selectSelectedStation } from "../selectors";
import { selectShowGrid } from "../selectors";
import { selectClipping } from "../selectors";
import { selectAlignmentView } from "../selectors";
import { selectAutoRecenter } from "../selectors";
import { selectVerticalClipping } from "../selectors";
import { alignmentActions } from "../slice";
import { AlignmentView } from "../types";
import { useGetAlignments } from "./useGetAlignments";

export function useGoToStation() {
    const {
        state: { view },
    } = useExplorerGlobals();
    const dispatch = useAppDispatch();

    const verticalClipping = useAppSelector(selectVerticalClipping);
    const clipping = useAppSelector(selectClipping);
    const alignmentView = useAppSelector(selectAlignmentView);
    const showGrid = useAppSelector(selectShowGrid);
    const autoRecenter = useAppSelector(selectAutoRecenter);
    const stationInfo = useAppSelector(selectSelectedStation);
    const alignments = useGetAlignments();

    const goToStation = useCallback(
        async ({
            keepOffset,
            station,
            newAlignmentView = alignmentView,
            keepCamera,
            clipVertical,
            updateStationInfo,
            fpObj,
        }: {
            station?: number;
            newAlignmentView?: AlignmentView;
            keepOffset?: boolean;
            keepCamera?: boolean;
            updateStationInfo?: boolean;
            clipVertical?: boolean;
            fpObj: FollowParametricObject;
        }) => {
            const measureView = view?.measure;
            if (!measureView || alignments.status !== AsyncStatus.Success) {
                return;
            }
            if (!station) {
                if (!stationInfo) {
                    return;
                }
                station = stationInfo.station;
            }
            const pos = await measureView.followPath.getCameraValues(station!, fpObj);
            if (!pos) {
                return;
            }

            const useKeepOffset = keepOffset != undefined ? keepOffset : !autoRecenter;

            const { position: pt, normal: dir } = pos;

            if (clipVertical ?? verticalClipping) {
                const up = glMatrix.equals(Math.abs(vec3.dot(vec3.fromValues(0, 0, 1), dir)), 1)
                    ? vec3.fromValues(0, 1, 0)
                    : vec3.fromValues(0, 0, 1);

                const right = vec3.cross(vec3.create(), up, dir);
                vec3.normalize(right, right);

                const newDir = vec3.cross(vec3.create(), up, right);
                vec3.normalize(newDir, newDir);
                if (vec3.dot(newDir, dir) < 0) {
                    vec3.negate(dir, newDir);
                } else {
                    vec3.copy(dir, newDir);
                }
            }
            const cameraDir = vec3.clone(dir);

            if (newAlignmentView === AlignmentView.OrthoTopDown) {
                vec3.set(cameraDir, 0, 0, 1);
            }

            let rotation: quat;
            if (newAlignmentView === AlignmentView.OrthoTopDown) {
                const cameraDir = getCameraDir(view.renderState.camera.rotation);
                if (Math.abs(vec3.dot(cameraDir, vec3.fromValues(0, 0, 1))) >= 0.99) {
                    rotation = view.renderState.camera.rotation;
                } else {
                    rotation = rotationFromDirection(vec3.fromValues(0, 0, 1));
                }
            } else {
                rotation = rotationFromDirection(dir);
            }

            const followPlane = view.renderState.clipping.planes.length
                ? view.renderState.clipping.planes[0].normalOffset
                : undefined;
            const offset = vec3.fromValues(0, 0, newAlignmentView === AlignmentView.OrthoTopDown ? -50 : 0);
            if (useKeepOffset && stationInfo) {
                if (newAlignmentView === AlignmentView.OrthoCrossSection && followPlane) {
                    const diffRot = quat.mul(
                        quat.create(),
                        rotation,
                        quat.invert(quat.create(), view.renderState.camera.rotation),
                    );
                    const prevOffset = vec3.sub(vec3.create(), stationInfo.point, view.renderState.camera.position);
                    const offsetLength = vec3.len(prevOffset);
                    vec3.normalize(offset, prevOffset);
                    vec3.transformQuat(offset, offset, diffRot);
                    vec3.scale(offset, offset, offsetLength);
                } else {
                    vec3.sub(offset, stationInfo.point, view.renderState.camera.position);
                }
            } else if (!useKeepOffset && newAlignmentView === AlignmentView.Pinhole) {
                vec3.scale(offset, dir, -1);
            }
            const offsetPt = vec3.sub(vec3.create(), pt, offset);

            if (newAlignmentView === AlignmentView.OrthoCrossSection) {
                dispatch(
                    renderActions.setCamera({
                        type: CameraType.Orthographic,
                        goTo: {
                            rotation,
                            position: offsetPt,
                            fov: useKeepOffset
                                ? view.renderState.camera.fov
                                : Math.min(60, view.renderState.camera.fov),
                            far: clipping + 0.02,
                        },
                        gridOrigo: pt as vec3,
                    }),
                );

                dispatch(
                    renderActions.setGrid({
                        enabled: showGrid,
                    }),
                );
            } else if (newAlignmentView === AlignmentView.OrthoTopDown) {
                dispatch(renderActions.setGrid({ enabled: false }));

                if (!keepCamera) {
                    dispatch(
                        renderActions.setCamera({
                            type: CameraType.Orthographic,
                            goTo: {
                                rotation,
                                position: offsetPt,
                                fov: useKeepOffset
                                    ? view.renderState.camera.fov
                                    : Math.min(60, view.renderState.camera.fov),
                            },
                            gridOrigo: pt as vec3,
                        }),
                    );
                }
            } else {
                dispatch(renderActions.setGrid({ enabled: false }));

                if (!keepCamera) {
                    dispatch(
                        renderActions.setCamera({
                            type: CameraType.Pinhole,
                            goTo: {
                                position: offsetPt,
                                rotation: useKeepOffset ? ([...view.renderState.camera.rotation] as Vec4) : rotation,
                            },
                        }),
                    );
                }
            }

            if (updateStationInfo) {
                dispatch(
                    alignmentActions.setSelectedStation({
                        direction: dir,
                        point: pt,
                        station,
                    }),
                );
            }

            const w = vec3.dot(dir, pt);
            dispatch(
                renderActions.setClippingPlanes({
                    enabled: true,
                    planes: [{ normalOffset: [dir[0], dir[1], dir[2], w], baseW: w, color: [0, 1, 0, 0.2] }],
                }),
            );
        },
        [verticalClipping, stationInfo, clipping, alignmentView, showGrid, dispatch, view, autoRecenter, alignments],
    );

    return goToStation;
}
