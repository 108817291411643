import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { AsyncStatus } from "types/misc";
import { getParentPath } from "utils/objectData";

import { selectAlignmentFromBookmark, selectAlignmentId, selectFollowObject } from "../selectors";
import { alignmentActions } from "../slice";
import { useGetAlignments } from "./useGetAlignments";
import { useGoToStation } from "./useGoToStation";

/**
 * Restore alignment state from a bookmark with follow path state
 */
export function useLoadAlignmentFromLegacyBookmark() {
    const alignmentId = useAppSelector(selectAlignmentId);
    const alignments = useGetAlignments();
    const fromBookmark = useAppSelector(selectAlignmentFromBookmark);
    const followObject = useAppSelector(selectFollowObject);

    const goToStation = useGoToStation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!fromBookmark || alignments.status !== AsyncStatus.Success) {
            return;
        }

        dispatch(alignmentActions.setFromBookmark(undefined));

        // IDs are volatile, so searching by ID is last resort
        const findAlignmentById = (id: number) => {
            for (const [name, alignment] of Object.entries(alignments.data)) {
                if (alignment.objectId === id) {
                    return { id: alignment.objectId, name };
                }
            }
        };

        // Names are more stable, but we were using different names in the past,
        // hence try to match either full name or parent name (was used for IFC)
        // or alignment name where slashes are underscores (landxml)
        const findAlignmentByName = (name: string) => {
            const parentName = getParentPath(name);
            const slashlessName = name.replace(/\//g, "_");
            for (const [alignmentName, alignment] of Object.entries(alignments.data)) {
                if (alignmentName === name || alignmentName === slashlessName || alignmentName === parentName) {
                    return { id: alignment.objectId, name: alignmentName };
                }
            }
        };

        if ("alignment" in fromBookmark) {
            dispatch(
                alignmentActions.setAlignment(
                    findAlignmentByName(fromBookmark.alignment.name) ?? findAlignmentById(fromBookmark.alignment.id),
                ),
            );
        } else if (fromBookmark.landXmlPathId !== undefined) {
            // Follow path only had alignment ID and no name.
            // Populate name once alignments are loaded, or set alignment to undefined if it's not found
            const alignment = findAlignmentById(fromBookmark.landXmlPathId);
            dispatch(alignmentActions.setAlignment(alignment));

            // Follow path had profile number, but we need additional data like direction and point.
            // Load it here based on profileNumberFromBookmark populated when loading a bookmark
            if (fromBookmark.profileNumber !== undefined && followObject && alignment) {
                goToStation({
                    station: fromBookmark.profileNumber,
                    fpObj: followObject,
                    keepCamera: true,
                    updateStationInfo: true,
                });
            }
        }
    }, [dispatch, alignmentId, alignments, fromBookmark, followObject, goToStation]);
}
