import { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { GroupStatus, objectGroupsActions, useDispatchObjectGroups, useObjectGroups } from "contexts/objectGroups";
import {
    ObjectVisibility,
    renderActions,
    selectDefaultVisibility,
    selectSubtrees,
    selectViewMode,
    SubtreeStatus,
} from "features/render";
import { ViewMode } from "types/misc";

import { deviationsActions } from "../deviationsSlice";
import { selectAllDeviationGroups, selectSelectedSubprofile } from "../selectors";

export function useHighlightDeviation() {
    const legendGroups = useAppSelector(selectAllDeviationGroups);
    const legendGroupsRef = useRef(legendGroups);
    legendGroupsRef.current = legendGroups;

    const subprofile = useAppSelector(selectSelectedSubprofile);
    const viewMode = useAppSelector(selectViewMode);

    const dispatch = useAppDispatch();
    const dispatchObjectGroups = useDispatchObjectGroups();

    const objectGroups = useObjectGroups();
    const objectGroupsRef = useRef(objectGroups);
    objectGroupsRef.current = objectGroups;
    const originalObjectGroupsRef = useRef(viewMode === ViewMode.Deviations ? objectGroups : null);

    const subtrees = useAppSelector(selectSubtrees);
    const originalSubtreesPointsRef = useRef(subtrees.points);
    const subtreesPointsRef = useRef(subtrees.points);
    subtreesPointsRef.current = subtrees.points;

    const defaultVisibility = useAppSelector(selectDefaultVisibility);
    const defaultVisibilityRef = useRef(defaultVisibility);
    defaultVisibilityRef.current = defaultVisibility;
    const originalDefaultVisibility = useRef(defaultVisibility);

    // If viewMode is already deviations at the start - we came from bookmark
    // and the view is controlled by the bookmark
    const installedRef = useRef(viewMode === ViewMode.Deviations);

    useEffect(() => {
        // Update object groups status based on legend groups status
        // when switching to deviations view mode or changing selected subprofile
        if (viewMode === ViewMode.Deviations && subprofile && legendGroupsRef.current) {
            let needUpdate = false;
            const newGroups = objectGroupsRef.current.map((group) => {
                const legendGroup = legendGroupsRef.current.find((g) => g.id === group.id);
                let desiredStatus = group.status;
                if (legendGroup) {
                    desiredStatus = legendGroup.status;
                } else if (group.status === GroupStatus.Selected) {
                    desiredStatus = GroupStatus.None;
                }
                if (desiredStatus === group.status) {
                    return group;
                }
                needUpdate = true;
                return { ...group, status: desiredStatus };
            });

            if (needUpdate) {
                dispatchObjectGroups(objectGroupsActions.set(newGroups));
            }
        }

        if (viewMode === ViewMode.Deviations && subprofile && !installedRef.current) {
            // Entering deviations mode
            // Remember object groups status when switching to deviations view mode
            // to restore statuses after going out of deviations view mode
            originalObjectGroupsRef.current = objectGroupsRef.current;
            originalDefaultVisibility.current = defaultVisibilityRef.current;
            originalSubtreesPointsRef.current = subtreesPointsRef.current;
            dispatch(renderActions.setDefaultVisibility(ObjectVisibility.Transparent));
            if (subtreesPointsRef.current === SubtreeStatus.Hidden) {
                dispatch(renderActions.toggleSubtree({ subtree: "points", newState: SubtreeStatus.Shown }));
            }
            installedRef.current = true;
        } else if (
            viewMode !== ViewMode.Deviations &&
            originalObjectGroupsRef.current &&
            legendGroupsRef.current &&
            installedRef.current
        ) {
            // Exiting deviations mode
            // Restore earlier remembered object groups status.
            // Legend groups are restored to their original state.
            // Other groups are restored to Selected state if they became None, because that was likely done by Deviations mode.
            // We don't restore entire object group array, because user could add/delete some groups
            // while in deviations mode
            const groupsById = new Map(originalObjectGroupsRef.current.map((group) => [group.id, group]));
            const newGroups = objectGroupsRef.current.map((group) => {
                const legendGroup = legendGroupsRef.current.find((g) => g.id === group.id);
                const originalStatus = groupsById.get(group.id)?.status;
                if (legendGroup && originalStatus) {
                    return group.status !== originalStatus ? { ...group, status: originalStatus } : group;
                }
                if (originalStatus === GroupStatus.Selected && group.status === GroupStatus.None) {
                    return { ...group, status: originalStatus };
                }
                return group;
            });

            dispatchObjectGroups(objectGroupsActions.set(newGroups));
            if (originalDefaultVisibility.current) {
                dispatch(renderActions.setDefaultVisibility(originalDefaultVisibility.current));
            }

            if (subtreesPointsRef.current !== originalSubtreesPointsRef.current) {
                dispatch(
                    renderActions.toggleSubtree({ subtree: "points", newState: originalSubtreesPointsRef.current }),
                );
            }

            installedRef.current = false;
        }
    }, [dispatch, dispatchObjectGroups, viewMode, subprofile]);

    useEffect(() => {
        // Sync legend groups with object groups
        if (!legendGroupsRef.current?.length || viewMode !== ViewMode.Deviations) {
            return;
        }

        for (const group of objectGroups) {
            const legendGroup = legendGroupsRef.current.find((g) => g.id === group.id);
            if (legendGroup && legendGroup.status !== group.status) {
                dispatch(
                    deviationsActions.toggleHiddenLegendGroup({
                        groupId: group.id,
                        hidden: group.status == GroupStatus.Hidden,
                    }),
                );
            }
        }
    }, [dispatch, objectGroups, viewMode]);
}
