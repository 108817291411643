import { HierarcicalObjectReference, ObjectData, ObjectDB, ObjectId, SearchOptions } from "@novorender/data-js-api";

interface SearchOptionsExt extends SearchOptions {
    skipPermissionCheck?: boolean;
}

/**
 * Wrapper around ObjectDB with some additional features
 * - Caches metadata for requests by object ID
 * - Filters out objects that the user don't have permissions to by default
 *
 * Also it patches `loadCompleteMetadata` with caching
 */
export class AppObjectDB implements ObjectDB {
    private readonly metadataCache = new ObjectMetadataCache();

    constructor(
        private readonly db: ObjectDB,
        private readonly availableIds: Set<ObjectId> | undefined,
    ) {
        const patchedDb = db as ObjectDB & { loadCompleteMetadata: (id: number) => Promise<ObjectData> };
        const originalLoadCompleteMetadata = patchedDb.loadCompleteMetadata;
        patchedDb.loadCompleteMetadata = (id: number) => {
            return this.metadataCache.getOrLoadMetadata(id, () => originalLoadCompleteMetadata(id));
        };
    }

    async getObjectMetdata(id: number, skipPermissionCheck = false): Promise<ObjectData> {
        if (skipPermissionCheck || !this.availableIds || this.availableIds.has(id)) {
            return this.metadataCache.getOrLoadMetadata(id, () => this.db.getObjectMetdata(id));
        }

        throw new ObjectAccessForbiddenError(id);
    }

    async *search(
        { skipPermissionCheck, ...filter }: SearchOptionsExt,
        signal: AbortSignal | undefined,
    ): AsyncIterableIterator<HierarcicalObjectReference> {
        for await (const obj of this.db.search(filter, signal)) {
            if (skipPermissionCheck || !this.availableIds || this.availableIds.has(obj.id)) {
                yield obj;
            }
        }
    }

    async descendants(
        object: HierarcicalObjectReference,
        signal: AbortSignal | undefined,
        skipPermissionCheck = false,
    ): Promise<ObjectId[]> {
        if (skipPermissionCheck || !this.availableIds || this.availableIds.has(object.id)) {
            return this.db.descendants(object, signal);
        }

        throw new ObjectAccessForbiddenError(object.id);
    }
}

export class ObjectAccessForbiddenError extends Error {
    constructor(public readonly objectId: ObjectId) {
        super(`Object ${objectId} is not available`);
    }
}

class ObjectMetadataCache {
    private cache = new Map<ObjectId, { promise: Promise<ObjectData>; timestamp: number }>();

    getOrLoadMetadata = async (objectId: ObjectId, load: (objectId: ObjectId) => Promise<ObjectData>) => {
        let cacheEntry = this.cache.get(objectId);

        if (!cacheEntry) {
            const promise = load(objectId);
            cacheEntry = { promise, timestamp: Date.now() };
            this.cache.set(objectId, cacheEntry);
            this.cleanCache();
        }

        return await cacheEntry.promise;
    };

    private cleanCache = () => {
        if (this.cache.size > 1000) {
            [...this.cache.entries()]
                .sort((a, b) => a[1].timestamp - b[1].timestamp)
                .slice(0, 200)
                .forEach(([key]) => this.cache.delete(key));
        }
    };
}
