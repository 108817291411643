import { useEffect, useRef } from "react";

import { useAppSelector } from "app/redux-store-interactions";
import { selectAlignmentId } from "features/alignment";
import { AsyncStatus } from "types/misc";

import { selectDeviationProfiles, selectSelectedProfile, selectSelectedSubprofile } from "../selectors";
import { useSelectDeviationProfile } from "./useSelectDeviationProfile";

export function useSyncWithAlignment() {
    const alignmentId = useAppSelector(selectAlignmentId);
    const profiles = useAppSelector(selectDeviationProfiles);
    const profile = useAppSelector(selectSelectedProfile);
    const subprofile = useAppSelector(selectSelectedSubprofile);
    const selectProfile = useSelectDeviationProfile();

    const prevAlignmentId = useRef<null | typeof alignmentId>(null);

    useEffect(() => {
        if (alignmentId === prevAlignmentId.current || profiles.status !== AsyncStatus.Success || !alignmentId) {
            return;
        }
        prevAlignmentId.current = alignmentId;

        // Don't do anything if current subprofile already uses this alignment
        if (subprofile?.centerLine?.objectId === alignmentId.id) {
            return;
        }

        // Search for a subprofile in the current profile
        if (profile) {
            const newSpIndex = profile.subprofiles.findIndex((sp) => sp.centerLine?.objectId === alignmentId.id);
            if (newSpIndex !== -1) {
                selectProfile({ profileId: profile.id, subprofileIndex: newSpIndex });
                return;
            }
        }

        // Search across all subprofiles
        for (const p of profiles.data.profiles) {
            const newSpIndex = p.subprofiles.findIndex((sp) => sp.centerLine?.objectId === alignmentId.id);
            if (newSpIndex !== -1) {
                selectProfile({ profileId: p.id, subprofileIndex: newSpIndex });
                return;
            }
        }
    }, [alignmentId, profiles, profile, subprofile, selectProfile]);
}
