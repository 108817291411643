import { Box, IconButton, type SpeedDialActionProps, Tooltip } from "@mui/material";
import { glMatrix, ReadonlyQuat, vec3, vec4 } from "gl-matrix";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { SpeedDialAction } from "components";
import { featuresConfig } from "config/features";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { getCameraDir } from "features/engine2D/utils";
import {
    getTopDownParams,
    selectCrossSectionClipping,
    selectDefaultTopDownElevation,
    selectTopDownSnapToAxis,
} from "features/orthoCam";
import { getSnapToPlaneParams } from "features/orthoCam/utils";
import {
    CameraType,
    renderActions,
    selectCameraDefaults,
    selectCameraType,
    selectClippingPlanes,
    selectViewMode,
} from "features/render";
import TwoDIcon from "media/icons/2d.svg?react";
import ThreeDIcon from "media/icons/3d.svg?react";
import { ViewMode } from "types/misc";

type Props = SpeedDialActionProps & {
    position?: { top?: number; right?: number; bottom?: number; left?: number };
    newDesign?: boolean;
};

export function OrthoShortcut({ position, newDesign, ...speedDialProps }: Props) {
    const { t } = useTranslation();
    const { nameKey, Icon } = featuresConfig["orthoShortcut"];
    const {
        state: { view },
    } = useExplorerGlobals(true);

    const cameraType = useAppSelector(selectCameraType);
    const elevation = useAppSelector(selectDefaultTopDownElevation);
    const snapToNearestAxis = useAppSelector(selectTopDownSnapToAxis) === undefined;
    const dispatch = useAppDispatch();
    const { planes } = useAppSelector(selectClippingPlanes);
    const viewMode = useAppSelector(selectViewMode);
    const crossSectionClipping = useAppSelector(selectCrossSectionClipping);
    const cameraDefaults = useAppSelector(selectCameraDefaults);
    const orthoFar = cameraDefaults.orthographic.clipping.far;

    const handleClick = async () => {
        if (cameraType === CameraType.Pinhole) {
            if (planes.length > 0 && !newDesign) {
                const switchToCrossSection = !isTopDownPlane(planes[0].normalOffset) && viewMode === ViewMode.Default;

                dispatch(
                    renderActions.setCamera({
                        type: CameraType.Orthographic,
                        goTo: {
                            ...getSnapToPlaneParams({
                                planeIdx: 0,
                                view,
                                anchorPos: planes[0].anchorPos,
                                inferOrthoFov: true,
                            }),
                            ...(switchToCrossSection ? { far: crossSectionClipping } : {}),
                        },
                    }),
                );

                if (switchToCrossSection) {
                    dispatch(renderActions.setViewMode(ViewMode.CrossSection));
                }
            } else {
                dispatch(
                    renderActions.setCamera({
                        type: CameraType.Orthographic,
                        goTo: await getTopDownParams({ view, elevation, snapToNearestAxis, far: orthoFar }),
                    }),
                );
                dispatch(renderActions.setTerrain({ asBackground: true }));
            }
        } else {
            if (
                planes.length > 0 &&
                isPlaneAlignedWithCamera(view.renderState.camera.rotation, planes[0].normalOffset)
            ) {
                const planeDir = vec3.fromValues(
                    planes[0].normalOffset[0],
                    planes[0].normalOffset[1],
                    planes[0].normalOffset[2],
                );
                dispatch(
                    renderActions.setCamera({
                        type: CameraType.Pinhole,
                        goTo: {
                            position: vec3.scaleAndAdd(
                                vec3.create(),
                                view.renderState.camera.position,
                                planeDir,
                                view.renderState.camera.fov,
                            ),
                            rotation: view.renderState.camera.rotation,
                        },
                    }),
                );
            } else {
                const { width, height } = view.canvas.getBoundingClientRect();
                const pick = await view.pick(Math.round(width / 2), Math.round(height / 2), {
                    sampleDiscRadius: 200,
                    searchType: "closestToPickCenter",
                    async: true,
                });

                if (pick) {
                    const controller = view.controllers[cameraDefaults.pinhole.controller];
                    const { fov } = view.renderState.camera;
                    const dir = getCameraDir(view.renderState.camera.rotation);
                    vec3.normalize(dir, dir);
                    vec3.negate(dir, dir);
                    const dist = fov / 2 / Math.tan(glMatrix.toRadian(controller.fov / 2));
                    const position = vec3.scaleAndAdd(vec3.create(), pick.position, dir, dist);

                    dispatch(
                        renderActions.setCamera({
                            type: CameraType.Pinhole,
                            goTo: {
                                position,
                                rotation: view.renderState.camera.rotation,
                                flyTime: 0,
                            },
                        }),
                    );
                } else {
                    dispatch(renderActions.setCamera({ type: CameraType.Pinhole }));
                }
            }
        }
    };

    if (newDesign) {
        return (
            <Tooltip title={cameraType === CameraType.Orthographic ? t("switchTo3d") : t("switchTo2d")} placement="top">
                <Box>
                    <IconButton onClick={handleClick}>
                        {cameraType === CameraType.Orthographic ? <ThreeDIcon /> : <TwoDIcon />}
                    </IconButton>
                </Box>
            </Tooltip>
        );
    }

    return (
        <SpeedDialAction
            {...speedDialProps}
            FabProps={{
                ...speedDialProps.FabProps,
                style: { ...position, position: "absolute" },
            }}
            active={cameraType === CameraType.Orthographic}
            onClick={handleClick}
            title={t(nameKey)}
            icon={<Icon />}
        />
    );
}

function isTopDownPlane(normalOffset: vec4) {
    return Math.abs(normalOffset[2]) >= 0.98;
}

function isPlaneAlignedWithCamera(rotation: ReadonlyQuat, normalOffset: vec4) {
    const cameraDir = getCameraDir(rotation);
    const normal = vec3.fromValues(normalOffset[0], normalOffset[1], normalOffset[2]);
    return Math.abs(vec3.dot(cameraDir, normal)) >= 0.98;
}
