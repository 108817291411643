import { Box, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app/redux-store-interactions";
import { MAX_FLOAT, selectStamp, StampKind } from "features/render";
import { useUom } from "features/uom/hooks/useUom";
import { UnitType } from "features/uom/spec";
import { convertAndFormat } from "features/uom/utils";

export function DeviationStamp() {
    const { t } = useTranslation();
    const stamp = useAppSelector(selectStamp);
    const uom = useUom();

    if (stamp?.kind !== StampKind.Deviation) {
        return null;
    }

    return (
        <Box sx={{ pointerEvents: "auto" }}>
            <MenuItem>
                {`${t("deviation")}: ${stamp.data.deviation === MAX_FLOAT ? t("outsideRange") : convertAndFormat(uom, stamp.data.deviation, UnitType.length, { decimals: 3 })}`}
            </MenuItem>
        </Box>
    );
}
