import { ObjectId } from "@novorender/data-js-api";

/**
 * Just a thin wrapper on top of ordered object ID array.
 * Trying to keep this interface as limited as possible.
 * Issue with native sets:
 * - Not big enough (up to ~16 million elements, we already need at least 20 million)
 * - Higher memory consumption
 *
 * This is the simplest implementation considering set immutability, but if memory is still a concern - maybe sparse bit set could give some benefits.
 * There's no `has` method because it's not needed at the moment,
 * but I expect it can be demanded at some point (e.g. a feature to show all groups containing selecting object),
 * so I'm hesitant to optimize it based on the lack of has method (e.g. delta + RLE).
 */
export class ObjectIdSet {
    private data: Uint32Array;

    constructor(numbers: Iterable<ObjectId>) {
        this.data = new Uint32Array(numbers).sort();
        this.removeDuplicates();
    }

    get size(): number {
        return this.data.length;
    }

    /**
     * We need to pass sorted iterables to highlights, so since array is sorted already (but it's an implementation detail),
     * add this shortcut to avoid creating a new array.
     */
    asSortedIterable(): Iterable<number> {
        return this.data;
    }

    *[Symbol.iterator](): IterableIterator<number> {
        for (const num of this.data) {
            yield num;
        }
    }

    private removeDuplicates() {
        if (this.data.length < 2) {
            return;
        }

        let index = 1;

        for (let i = 1; i < this.data.length; i++) {
            if (this.data[i] !== this.data[i - 1]) {
                this.data[index] = this.data[i];
                index++;
            }
        }

        if (index !== this.data.length) {
            // The expectation is that there are few duplicates, so it's not worth to make a new array with slice.
            this.data = this.data.subarray(0, index);
        }
    }
}
