import { rotationFromDirection, View } from "@novorender/api";
import { glMatrix, quat, ReadonlyVec3, vec3 } from "gl-matrix";

import { pointToPlaneDistance } from "utils/math";

export async function getTopDownParams({
    view,
    elevation,
    snapToNearestAxis,
    far,
}: {
    view: View;
    elevation: number | undefined;
    snapToNearestAxis?: boolean;
    far: number;
}): Promise<{
    position: vec3;
    rotation: quat;
    fov: number;
}> {
    const { width, height } = view.canvas.getBoundingClientRect();
    const pick = await view.pick(Math.round(width / 2), Math.round(height / 2), {
        sampleDiscRadius: 200,
        async: true,
        searchType: "closestToPickCenter",
    });

    let position = vec3.clone(view.renderState.camera.position);
    let z = elevation ?? position[2];
    let fov: number;
    if (pick) {
        position = pick.position;
        // Idea behind the formula (based on top-down 3d->2d transition, but we apply it in any case):
        // There's a right triangle formed by camera, pick position in the center of the screen, and the top point of the screen.
        // Triangle is not exactly right because pick position might be slightly off the center.
        //      C
        //     /|
        //    / |
        //   /  |
        //  /   |
        // T____P
        // where C - camera, T - top of the screen, P - pick position
        // We know distance C_P and angle CT_CP (half pinhole FOV), so we can find T_P distance using tan(CT_CP)
        // Then T_P multiplied by 2 gives us screen height, which is ortho FOV.
        fov =
            Math.tan(glMatrix.toRadian(view.renderState.camera.fov / 2)) *
            vec3.dist(view.renderState.camera.position, position) *
            2;
        z = position[2] + vec3.dist(view.renderState.camera.position, position);
    } else {
        fov = z;
    }

    const bs = view.renderState.scene?.config.boundingSphere;
    const maxY = bs ? bs.center[1] + bs.radius : 10000;

    position[2] = Math.min(maxY, Math.min(far * 0.9, z));

    return {
        position,
        rotation: getTopDownRotation(view, snapToNearestAxis),
        fov: Math.max(fov, 20),
    };
}

export function getTopDownRotation(view: View, snapToNearestAxis = false) {
    return rotationFromDirection([0, 0, 1], snapToNearestAxis ? view.renderState.camera.rotation : undefined);
}

export function getSnapToPlaneParams({
    planeIdx,
    view,
    anchorPos,
    offset,
    inferOrthoFov,
}: {
    planeIdx: number;
    view: View;
    anchorPos?: ReadonlyVec3;
    offset?: number;
    /**
     * Set FOV based on the original distance from camera to the clipping plane (relevant for ortho camera)
     * to preserve visual distance from the plane.
     */
    inferOrthoFov?: boolean;
}): {
    position: vec3;
    rotation: quat;
    fov: number;
    far: number;
} {
    const p = view.renderState.clipping.planes[planeIdx].normalOffset;
    const dir = vec3.fromValues(p[0], p[1], p[2]);
    const rotation = rotationFromDirection(dir);
    let position: ReadonlyVec3;
    if (anchorPos) {
        position = vec3.clone(anchorPos);
        if (offset) {
            vec3.scaleAndAdd(position, position, dir, offset);
        }
    } else {
        const planePoint = vec3.scaleAndAdd(vec3.create(), vec3.create(), dir, p[3]);
        const v = vec3.sub(vec3.create(), view.renderState.camera.position, planePoint);
        const d = vec3.dot(v, dir);
        position = vec3.scaleAndAdd(vec3.create(), view.renderState.camera.position, dir, -d);
    }

    const fov = inferOrthoFov ? pointToPlaneDistance(view.renderState.camera.position, p) : view.renderState.camera.fov;

    return { position, rotation, fov, far: 0.001 };
}
