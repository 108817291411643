import {
    Alignment,
    HorizontalAlignment,
    StationDrawObject,
    StationsDrawObject,
    StationSegmentDrawObject,
} from "@novorender/api";
import { ReadonlyVec2, ReadonlyVec3, vec2, vec3 } from "gl-matrix";

import { processDrawObjectTextTemplates, processDrawPartTextTemplates } from "features/uom/draw";
import { Uom } from "features/uom/hooks/useUom";
import { closestPointOnLine } from "utils/math";

export type ClosestPointToAlignment = {
    alignment: string;
    distance: number;
    projectedPoint: ReadonlyVec3;
    id: number;
};

export function updateClosestPoint(
    pos: ReadonlyVec2,
    currentClosest: ClosestPointToAlignment | undefined,
    alignmentName: string,
    alignment: HorizontalAlignment,
    alignmentData: Alignment,
) {
    for (const part of alignment.segment.parts) {
        const vertices = part.vertices2D;
        if (vertices) {
            for (let i = 1; i < vertices.length; ++i) {
                if (vec2.equals(vertices[i - 1], vertices[i])) {
                    continue;
                }
                const projection = closestPointOnLine(vec2.create(), pos, vertices[i - 1], vertices[i]);
                const dist = vec2.dist(projection.point, pos);
                if (currentClosest === undefined || dist < currentClosest.distance) {
                    currentClosest = {
                        distance: dist,
                        alignment: alignmentName,
                        id: alignmentData.objectId,
                        projectedPoint: vec3.lerp(
                            vec3.create(),
                            part.vertices3D[i - 1],
                            part.vertices3D[i],
                            projection.parameter,
                        ),
                    };
                }
            }
        }
    }
    return currentClosest;
}

export function getStationCross(vertices3D: ReadonlyVec3[], directionVertices?: ReadonlyVec2[]) {
    if (vertices3D?.length === 2 && directionVertices?.length === 2) {
        const dir = vec2.sub(
            vec2.create(),
            vec2.fromValues(vertices3D[0][0], -vertices3D[0][1]),
            vec2.fromValues(vertices3D[1][0], -vertices3D[1][1]),
        );
        const side = vec2.fromValues(-dir[1], dir[0]);
        vec2.normalize(side, side);

        return {
            line: [
                vec2.scaleAndAdd(vec2.create(), directionVertices[0], side, 30),
                vec2.scaleAndAdd(vec2.create(), directionVertices[0], side, -30),
            ] as [ReadonlyVec2, ReadonlyVec2],
            side,
        };
    }
}

export function processHorizontalDrawItemTextTemplates(uom: Uom, alignment: HorizontalAlignment) {
    processDrawPartTextTemplates(uom, alignment.curvatures);
    processDrawPartTextTemplates(uom, alignment.pointsOfCurvature);
    processDrawObjectTextTemplates(uom, alignment.segment);
}

export function processStationsDrawObjectTextTemplates(uom: Uom, drawObj: StationsDrawObject) {
    processDrawPartTextTemplates(uom, drawObj.stationInfo);
    for (const drawPart of drawObj.stationLines) {
        processDrawPartTextTemplates(uom, drawPart);
    }
    for (const drawPart of drawObj.stationMinorLines) {
        processDrawPartTextTemplates(uom, drawPart);
    }
}

export function processStationSectionDrawObjectTextTemplates(uom: Uom, drawObj: StationSegmentDrawObject) {
    if (drawObj.labels) {
        processDrawPartTextTemplates(uom, drawObj.labels);
    }
    processDrawPartTextTemplates(uom, drawObj.segment);
    processDrawPartTextTemplates(uom, drawObj.stations);
}

export function processStationDrawObjectTextTemplates(uom: Uom, drawObj: StationDrawObject) {
    processDrawPartTextTemplates(uom, drawObj.direction);
    processDrawObjectTextTemplates(uom, drawObj.info);
}
