import { FollowParametricObject } from "@novorender/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { AsyncState, AsyncStatus } from "types/misc";

import { selectAlignmentId } from "../selectors";
import { selectAlignmentIds } from "../selectors";
import { alignmentActions } from "../slice";

export function useFollowAlignment() {
    const {
        state: { view },
    } = useExplorerGlobals(true);
    const { t } = useTranslation();

    const alignmentId = useAppSelector(selectAlignmentId);
    const alignmentIds = useAppSelector(selectAlignmentIds);
    const dispatch = useAppDispatch();

    const [objects, setObjects] = useState<AsyncState<FollowParametricObject>>({ status: AsyncStatus.Initial });

    useEffect(() => {
        loadFpObjects();

        async function loadFpObjects() {
            if (alignmentIds.status !== AsyncStatus.Success) {
                return;
            }

            setObjects({ status: AsyncStatus.Loading });
            if (alignmentId) {
                try {
                    const fp = await view.measure?.followPath.followParametricObjects([alignmentId.id]);

                    if (!fp) {
                        setObjects({ status: AsyncStatus.Error, msg: t("objectsCantBeFollowed") });
                        dispatch(alignmentActions.setFollowObject(undefined));
                        return;
                    }

                    setObjects({ status: AsyncStatus.Success, data: fp });
                    dispatch(alignmentActions.setFollowObject(fp));
                } catch {
                    setObjects({ status: AsyncStatus.Error, msg: t("errorOccurred") });
                    dispatch(alignmentActions.setFollowObject(undefined));
                }
            } else {
                setObjects({ status: AsyncStatus.Error, msg: t("alignmentDoesNotExists") });
                dispatch(alignmentActions.setFollowObject(undefined));
            }
        }
    }, [view, dispatch, alignmentId, alignmentIds, t]);

    return objects;
}
