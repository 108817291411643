export const UOM_DEFAULT_DECIMALS = 2;

export enum Unit {
    m = "m",
    m2 = "m2",
    m3 = "m3",
    ft = "ft",
    ft2 = "ft2",
    ft3 = "ft3",
    in = "in",
    deg = "deg",
    rad = "rad",
    // "cd/m2": { abbreviation: "cd/m2" },
}

export type UnitInfo = {
    abbreviation: string;
    decimals?: number;
};

export const UNITS: { [key in Unit]: UnitInfo } = {
    m: { abbreviation: "m" },
    m2: { abbreviation: "m2" },
    m3: { abbreviation: "m3" },
    ft: { abbreviation: "ft" },
    ft2: { abbreviation: "ft2" },
    ft3: { abbreviation: "ft3" },
    in: { abbreviation: "in" },
    deg: { abbreviation: "deg", decimals: 1 },
    rad: { abbreviation: "rad" },
    // "cd/m2": { abbreviation: "cd/m2" },
};

export enum UnitType {
    length = "length",
    area = "area",
    volume = "volume",
    angle = "angle",
}

export type UnitTypeInfo = {
    abbreviation: string;
    baseUnit: Unit;
};

export const UNIT_TYPES: { [key in UnitType]: UnitTypeInfo } = {
    length: { abbreviation: "L" as const, baseUnit: Unit.m },
    area: { abbreviation: "A" as const, baseUnit: Unit.m2 },
    volume: { abbreviation: "V" as const, baseUnit: Unit.m3 },
    angle: { abbreviation: "An" as const, baseUnit: Unit.deg },
    // luminance: { abbreviation: "lum" as const, baseUnit: "cd/m2" as const },
};

export const UNIT_TYPE_BY_ABBREVIATION = new Map<string, UnitType>();
for (const [key, value] of Object.entries(UNIT_TYPES)) {
    UNIT_TYPE_BY_ABBREVIATION.set(value.abbreviation, key as UnitType);
}

export enum UnitSystem {
    metric = "metric",
    imperial = "imperial",
}

export type UnitSystemInfo = {
    unitTypes: { [key in UnitType]: Unit };
};

export const UNIT_SYSTEMS: { [key in UnitSystem]: UnitSystemInfo } = {
    metric: {
        unitTypes: {
            [UnitType.length]: Unit.m,
            [UnitType.area]: Unit.m2,
            [UnitType.volume]: Unit.m3,
            [UnitType.angle]: Unit.deg,
            // luminance: "cd/m2",
        },
    },
    imperial: {
        unitTypes: {
            [UnitType.length]: Unit.ft,
            [UnitType.area]: Unit.ft2,
            [UnitType.volume]: Unit.ft3,
            [UnitType.angle]: Unit.deg,
            // luminance: "cd/m2",
        },
    },
};

export type FormatOptions = {
    decimals?: number;
};
